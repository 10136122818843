import io from 'socket.io-client';

let socket = null;
let isReconnecting = false;

const createSocket = async () => {
  // Prevent concurrent reconnect attempts
  if (isReconnecting) return socket;

  if (socket) {
    console.log('Reusing existing Socket.IO instance');
    return socket;
  }

  console.log('Creating new Socket.IO instance');
  try {
    socket = io({
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2000,
      timeout: 5000,
    });

    // Handle connection events
    socket.on('connect', () => {
      console.log('%cSocket.IO Connected Successfully ✅', 'color: green; font-weight: bold;');
      isReconnecting = false;
    });

    socket.on('disconnect', (reason) => {
      console.warn(`%cSocket.IO Disconnected 🚫 Reason: ${reason}`, 'color: orange; font-weight: bold;');
    });

    socket.on('connect_error', async (error) => {
      console.error('%cSocket.IO Connection Error ❌', 'color: red; font-weight: bold;', error.message);
      await reconnectSocket();
    });

    return socket;
  } catch (err) {
    console.error('Error creating socket:', err.message);
    isReconnecting = false;
    throw err;
  }
};

const reconnectSocket = async () => {
  try {
    isReconnecting = true;
    if (socket) {
      console.log('Disconnecting stale socket...');
      socket.disconnect();
      socket = null;
    }
    console.log('Attempting to reconnect...');
    await createSocket(); // Recreate the socket
  } catch (err) {
    console.error('Reconnection failed:', err.message);
  }
};

export default createSocket;
