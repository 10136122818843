import React, { Component } from 'react';
import MediaObject from '../mediaObject'

class WebCamSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDeviceId: null,
    };
  }

  // Handle selecting a video device
  handleDeviceSelect = (deviceId) => {
    const { selectedDeviceId } = this.state;

    if (selectedDeviceId === deviceId) {
      // Deselect the current stream
      this.setState({ selectedDeviceId: null });
    } else {
      // Select the new device
      this.setState({ selectedDeviceId: deviceId });
    }
  };

  // Handle activating the selected feed
  handleActivateFeed = () => {
    const { selectedDeviceId } = this.state;
    const { localStreams, cameraType, onSelectFeed } = this.props;

    // Find the stream object for the selected device
    const selectedStream = localStreams.find(feed => feed.deviceId === selectedDeviceId);

    if (selectedStream) {
      // Update the selectedStream with the cameraType from the parent component
      const updatedStream = {
        ...selectedStream,
        cameraType, // Add the cameraType (e.g., 'faceview', 'sideview', etc.)
      };

      let mediaObject = new MediaObject(updatedStream)

      // Pass the updated stream object to the parent component
      onSelectFeed(mediaObject);
    }
  };

  render() {
    const { localStreams, closeModal } = this.props;
    const { selectedDeviceId } = this.state;

    return (
      <div style={styles.overlay}>
        <div style={styles.modal}>
          <h3>Select a Camera Feed</h3>
          <div style={styles.deviceList}>
            {localStreams.map(device => (
              <div
                key={device.deviceId}
                style={{
                  ...styles.deviceItem,
                  backgroundColor: selectedDeviceId === device.deviceId ? '#555' : 'transparent',
                }}
                onClick={() => this.handleDeviceSelect(device.deviceId)}
              >
                <div style={styles.thumbnailColumn}>
                  <video
                    playsInline
                    ref={videoElement => {
                      if (videoElement && device.stream) {
                        videoElement.srcObject = device.stream;
                        videoElement.play();
                      }
                    }}
                    autoPlay
                    muted
                    style={styles.videoThumbnail}
                  />
                </div>
                <div style={styles.nameColumn}>
                  <p>{device.label || 'Unknown Camera'}</p>
                </div>
                <div style={styles.buttonColumn}>
                  <button style={styles.selectButton}>
                    {selectedDeviceId === device.deviceId ? 'Deselect' : 'Select'}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={this.handleActivateFeed}
            disabled={!selectedDeviceId}
            style={styles.activateButton}
          >
            Activate Feed
          </button>
          <button onClick={closeModal} style={styles.closeButton}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    padding: '20px',
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: '10px',
    width: '500px',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  deviceList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px',
  },
  deviceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '5px',
  },
  thumbnailColumn: {
    width: '100px',
  },
  videoThumbnail: {
    width: '100%',
    height: '75px',
    backgroundColor: '#000',
    borderRadius: '5px',
  },
  nameColumn: {
    flexGrow: 1,
    paddingLeft: '10px',
  },
  buttonColumn: {
    width: '100px',
  },
  selectButton: {
    padding: '5px 10px',
    backgroundColor: '#1c9c46',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  activateButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#1c9c46',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
  },
  closeButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#555',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
  },
};

export default WebCamSelector;
