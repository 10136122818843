import React from 'react';

class MediaObject {
  constructor({ isLocal = true, peerId = null, stream_id = null, stream = null, cameraType = null, deviceId = null, type = 'webcam', isStaging = false, remove = false }) {
    this.id = this.generateUniqueId(); // Unique ID for each media object
    this.ref = null; // React ref to manage DOM interaction
    this.cameraType = cameraType;
    this.peerId = peerId;
    this.isLocal = isLocal; // Boolean to track if this media is local or remote
    this.deviceId = deviceId; // The device ID for the media (e.g., specific webcam or mic device ID)
    this.stream = stream; // The actual MediaStream object from the device
    this.stream_id = stream_id ? stream_id : stream ? stream.id : null; // The ID of the stream itself

    // Set mediaType, videoType, and audioType based on the provided type
    this.type = type;
    this.mediaType = type === 'audio' ? 'audio' : 'video';
    this.videoType = type === 'webcam' ? 'webcam' : (type === 'screenshare' ? 'screenshare' : null);
    this.audioType = type === 'audio' ? 'standAlone' : (type === 'webcam' || type === 'screenshare' ? 'attached' : null);

    this.isStaging = isStaging; // Added flag for staging
    this.remove = remove; // Added flag for removal
  }

  // Utility method to generate a unique ID
  generateUniqueId() {
    return Math.random().toString(36).substr(2, 10);
  }

  // Method to attach the stream to the ref and play it
  play() {
    if (this.ref?.current && this.stream) {
      this.ref.current.srcObject = this.stream;
      this.ref.current.play();
    } else {
      console.log('not ready to play, no stream or no valid ref: ', this.ref, this.stream)
    }
  }

  // Method to handle setting ref and playing media
  setRef = (ref) => {
    this.ref = ref;
    if (this.ref && this.stream) {
      this.ref.srcObject = this.stream;
      this.ref.play().catch(error => {
        console.error('Error trying to play media:', error);
      });
      if (this.isLocal) {
        this.ref.muted = true;
      }
    } else {
      console.log('not ready to play, no stream or no valid ref: ', this.ref, this.stream);
    }
  };

  // Method to stop the media stream (cleanup)
  stop() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }

  toJSON() {
    return {
      id: this.id,
      cameraType: this.cameraType,
      deviceId: this.deviceId,
      type: this.type,
      peerId: this.peerId,
      mediaType: this.mediaType,
      videoType: this.videoType,
      audioType: this.audioType,
    };
  }
}

export default MediaObject;
