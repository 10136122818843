import React, { Component } from 'react';
import { CommsContext } from '../CommsContext';

import MediaObject from '../mediaObject';
import Media from './media';

class AudioSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioDevices: [],
      selectedAudioDevice: null,
    };
  }

  async componentDidMount() {
    try {
      // Check and request microphone permission
      const microphonePermission = await navigator.permissions.query({ name: 'microphone' });
      console.log('Microphone permission state:', microphonePermission.state);

      // If microphone permission is denied or not granted, request access
      if (microphonePermission.state !== 'granted') {
        try {
          // Trigger a prompt for microphone access
          await navigator.mediaDevices.getUserMedia({ audio: true });
          console.log('Microphone access granted');
        } catch (error) {
          console.error('Error accessing microphone:', error);
          return; // Stop execution if no permission
        }
      }

      // Fetch available audio devices after permission is granted
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      this.setState({ audioDevices });

      // Load the saved audio feed from localStorage
      const savedFeeds = JSON.parse(localStorage.getItem('activeFeeds')) || [];

      const savedAudioFeed = savedFeeds.find(feed => feed.type === 'audio');

      if (savedAudioFeed) {
        const deviceId = savedAudioFeed.deviceId;

        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });
          const mediaObject = new MediaObject({
            isLocal: true,
            deviceId: deviceId,
            type: 'audio',
            stream: audioStream,
          });
          this.setState({ selectedAudioDevice: mediaObject });
          this.context.activateFeeds([mediaObject]);
        } catch (error) {
          console.error('Error accessing audio device:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching audio devices:', error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.resetView && prevProps.resetView != this.props.resetView) {
      this.handleAudioDeviceSelect(null);
    }
  }

  // Handle audio device selection
  handleAudioDeviceSelect = async (event) => {
    const deviceId = event?.target?.value || null;

    // No audio selected, remove the feed
    if (!deviceId) {
      const savedFeeds = JSON.parse(localStorage.getItem('activeFeeds')) || [];
      const updatedFeeds = savedFeeds.filter(feed => feed.type !== 'audio');
      localStorage.setItem('activeFeeds', JSON.stringify(updatedFeeds));
      this.setState({ selectedAudioDevice: null });
      const feed = savedFeeds.filter(feed => feed.type === 'audio');
      if (feed) {
        this.context.removeFeedById(feed.id); // Remove feed
      }
      return;
    }

    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });

      // Create a new MediaObject
      const mediaObject = new MediaObject({
        isLocal: true,
        deviceId: deviceId,
        type: 'audio',
        stream: audioStream,
      });

      // Save to localStorage
      const savedFeeds = JSON.parse(localStorage.getItem('activeFeeds')) || [];
      const updatedFeeds = [...savedFeeds.filter(feed => feed.type !== 'audio'), mediaObject];
      localStorage.setItem('activeFeeds', JSON.stringify(updatedFeeds));

      // console.log('updatedFeeds: ', updatedFeeds)
      // console.log('JSON.stringify(updatedFeeds): ', JSON.stringify(updatedFeeds))

      // Update state and activate the feed
      this.setState({ selectedAudioDevice: mediaObject });
      this.context.activateFeeds([mediaObject]);

    } catch (error) {
      console.error('Error accessing audio device:', error);
    }
  };

  render() {
    return (
      <div {...this.props.styles.rowStyle}>
        <div>Comms:</div>
        <select onChange={this.handleAudioDeviceSelect} value={this.state.selectedAudioDevice?.deviceId || ''}>
          <option value="">No Audio</option>
          {this.state.audioDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Audio device ${device.deviceId}`}
            </option>
          ))}
        </select>
        {this.state.selectedAudioDevice ? 
          <div>
            <Media mediaObject={this.state.selectedAudioDevice} renderControlIcons={true} />
          </div>
          :
          null}
      </div>
    );
  }
}

AudioSelector.contextType = CommsContext;

export default AudioSelector;
