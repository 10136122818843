import React, { Component } from 'react';
import AudioVisualizer from './audioVisualizers';

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muteState: false, // Store mute state
      pauseState: false, // Store pause state
    };
  }

  // Toggle mute/unmute for the media
  toggleMute = () => {
    const { mediaObject } = this.props;
    const { muteState } = this.state;

    this.setState({ muteState: !muteState });

    if (mediaObject.stream) {
      mediaObject.stream.getTracks().forEach(track => {
        if (track.kind === 'audio') {
          track.enabled = muteState; // Mute/unmute the audio track
        }
      });
    }
  };

  // Toggle play/pause for the media
  togglePause = () => {
    const { mediaObject } = this.props;
    const { pauseState } = this.state;

    this.setState({ pauseState: !pauseState });

    if (mediaObject.ref.current) {
      if (pauseState) {
        mediaObject.ref.current.play(); // Play the video/audio element
      } else {
        mediaObject.ref.current.pause(); // Pause the video/audio element
      }
    }
  };

  renderControlIcons = () => {
    if (this.props.renderControlIcons) {
      const { mediaObject } = this.props;
      const { muteState, pauseState } = this.state;

      return (
        <div style={{ display: 'flex', gap: '5px', position: 'absolute', bottom: '5px', right: '5px', zIndex: 5 }}>
          {/* Pause/Play Button for Video Streams */}
          {mediaObject.mediaType === 'video' && (
            <button
              onClick={this.togglePause}
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                border: 'none',
                borderRadius: '3px',
                cursor: 'pointer',
                padding: '5px',
                zIndex: 10,
              }}
            >
              {pauseState ? '▶️' : '⏸'}
            </button>
          )}

          {/* Mute/Unmute Button for Audio or Video with Audio, but not for webcam */}
          {(mediaObject.mediaType === 'audio' ||
            (mediaObject.mediaType === 'video' && mediaObject.audioType === 'attached' && mediaObject.videoType === 'screenshare')) && (
              <button
                onClick={this.toggleMute}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '3px',
                  cursor: 'pointer',
                  padding: '5px',
                  zIndex: 10,
                }}
              >
                {muteState ? '🔇' : '🔊'}
              </button>
            )}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { mediaObject } = this.props;
    // console.log('MediaObject: ', mediaObject)
    return <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>

      {/* Render video elements based on videoType */}
      {mediaObject.mediaType === 'video' ? (
        <>
          <video
            ref={mediaObject.setRef}
            autoPlay
            playsInline
            muted={mediaObject.audioType === 'attached'} // Mute video if audio is attached
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensures no black bars on the sides
              backgroundColor: 'black',
            }}
          />
          {this.renderControlIcons()}
        </>
      ) : (
        <>
          {/* Render audio elements based on audioType */}
          <audio
            ref={mediaObject.setRef}
            autoPlay
            style={{ display: mediaObject.audioType === 'attached' ? 'none' : 'block' }}
          />
          {/* Render AudioVisualizer for standalone audio */}
          {mediaObject.audioType === 'standAlone' && (
            <AudioVisualizer
              stream={mediaObject.stream}
              style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
            />
          )}
          {this.renderControlIcons()}
        </>
      )}
    </div>;
  }
}

export default Media;
