import React, { Component } from 'react';
import { CommsContext } from '../CommsContext';
import { style } from 'glamor';


class TogglableButton extends Component {
  static contextType = CommsContext;

  constructor(props) {
    super(props);
    const savedState = localStorage.getItem('goLive') === 'true';
    this.state = {
      goLive: savedState || false,
    };

  }

  // componentDidMount() {
  //   this.context.setGoLive(this.state.goLive);
  // }

  toggleConnection = () => {
    this.setState(
      (prevState) => ({ goLive: !prevState.goLive }),
      () => {
        localStorage.setItem('goLive', this.state.goLive);
        this.context.setGoLive(this.state.goLive);
      }
    );
  };

  render() {
    return (
      <div {...styles.rowStyle}>
        <button {...styles.buttonStyle} onClick={this.toggleConnection}>
          {this.state.goLive ? 'Disconnect' : 'Connect'}
        </button>
      </div>
    );
  }
}

// Add Glamor styles
const styles = {
  rowStyle: style({
    margin: '20px 0',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  }),
  buttonStyle: style({
    padding: '10px 20px',
    backgroundColor: '#1c9c46',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
  }),
};

export default TogglableButton;
