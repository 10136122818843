/*****************************************************
 * NewSite.Com <Main>
 * John Minton <cjohnweb@gmail.com>
 *****************************************************/

import React, { Component } from 'react';
import VideoChat from './videoChat';
import { CommsContext } from './CommsContext';

class Main extends Component {
    static contextType = CommsContext;

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            isAuthenticated: localStorage.getItem('isAuthenticated') === 'true'
        };
    }

    componentDidMount() {
        // Check if authenticated and goLive was true
        if (this.state.isAuthenticated && localStorage.getItem('goLive') === 'true') {
            this.context.setAuthentication(true);
            this.context.setGoLive(true);
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Submitting password...');
            const response = await fetch('/api/temp-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password: this.state.password })
            });
            const data = await response.json();
            console.log('Auth response:', data);
            
            // Update both local state and CommsContext authentication
            if (data.success) {
                localStorage.setItem('isAuthenticated', 'true');
            }
            
            this.setState({ isAuthenticated: data.success }, () => {
                console.log('Updated isAuthenticated:', this.state.isAuthenticated);
                // Check localStorage and call setGoLive after authentication succeeds
                if (data.success && localStorage.getItem('goLive') === 'true') {
                    this.context.setGoLive(true);
                }
            });
            this.context.setAuthentication(data.success);
            
            if (!data.success) {
                this.setState({ password: '' });
                localStorage.removeItem('isAuthenticated');
            }
        } catch (error) {
            console.error('Auth error:', error);
            this.setState({ password: '' });
            this.context.setAuthentication(false);
            localStorage.removeItem('isAuthenticated');
        }
    }

    render() {
        console.log('Rendering with isAuthenticated:', this.state.isAuthenticated);
        if (!this.state.isAuthenticated) {
            return (
                <div style={styles.container}>
                    <div style={styles.loginBox}>
                        <form onSubmit={this.handleSubmit}>
                            <input
                                type="password"
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                style={styles.input}
                                autoFocus
                            />
                            <button type="submit" style={styles.button}>
                                Enter
                            </button>
                        </form>
                    </div>
                </div>
            );
        }

        return <VideoChat />;
    }
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
    },
    loginBox: {
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    input: {
        padding: '8px',
        marginRight: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px'
    },
    button: {
        padding: '8px 16px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer'
    }
};

export default Main;
