import React, { Component } from 'react';
import { CommsContext } from '../CommsContext';

import MediaObject from '../mediaObject';
import Media from './media';


class ScreenShareSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaObjects: [], // Array to store multiple screenshares
    };
  }

  // componentDidMount() {
  //   // Load any saved configuration or feeds, excluding screenshares
  //   const savedFeeds = JSON.parse(localStorage.getItem('activeFeeds')) || [];
  //   const mediaObjects = savedFeeds.map(feed => new MediaObject(feed));
  //   this.setState({ mediaObjects });
  // }

  startScreenShare = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

      const mediaObject = new MediaObject({
        isLocal: true,
        type: 'screenshare',
        stream: screenStream,
        deviceId: null,
        cameraType: 'screenshare',
        isStaging: false,
        remove: false
      });

      // Add the new screenshare to state
      this.setState((prevState) => {
        const updatedMediaObjects = [...prevState.mediaObjects, mediaObject];
        return { mediaObjects: updatedMediaObjects };
      });

      // Pass only the new feed to the onSelectFeed function
      this.context.activateFeeds([mediaObject]);

    } catch (error) {
      console.error('Error starting screen share:', error);
    }
  };

  stopScreenShare = (id) => {
    this.context.removeFeedById(id);

    this.setState((prevState) => {
      const updatedMediaObjects = prevState.mediaObjects.filter(obj => obj.id !== id);
      return { mediaObjects: updatedMediaObjects };
    });

  };


  render() {
    return (
      <div {...this.props.styles.rowStyle}>
        <div>Screen Sharing:</div>
        <button onClick={this.startScreenShare}>
          Add Screen Share
        </button>

        <div>
          {this.state.mediaObjects.map((mediaObj, index) => (
            <div key={index} {...this.props.styles.shareStyle}>
              <Media mediaObject={mediaObj} renderControlIcons={false} />
              <button onClick={() => this.stopScreenShare(mediaObj.id)}>Remove</button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ScreenShareSelector.contextType = CommsContext;

export default ScreenShareSelector;
