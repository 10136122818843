import React, { Component } from 'react';
import { style } from 'glamor';

import { CommsContext } from '../CommsContext';

import CameraFeedCollector from './cameraFeedCollector';
import GoLiveButton from './goLiveButton';
import WebCamSelector from './webcamSelector';
import ScreenshareSelector from './screenshareSelector';
import AudioSelector from './audioSelector';
import MediaObject from '../mediaObject';
import Media from './media';

// import TacView from './tacview';

class EITDServices extends Component {
  static contextType = CommsContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedCamera: null,
      showModal: false,
      resetView: false,
      activeFeeds: [], // An array of objects with stream and metadata
      videoDevices: [], // Devices fetched from the CameraFeedCollector
    };

    // Defines available camera feed types (faceview, sideview, etc.)
    this.cameraFeeds = [
      'frontview',
      'secondary',
      'tertiary'
    ];

    this.localStreams = []; // Collection of all available local streams
  }

  // Open the media selector and pass the selected camera type
  openMediaSelector = (cameraType) => {
    this.setState({ selectedCamera: cameraType, showModal: true });
  };

  // Close the media selector
  closeSelector = () => {
    this.setState({ showModal: false });
  };

  // Handle the selected feed from the media selector
  onSelectFeed = (feedObject) => {
    // Call the shared updateFeeds function with the single feed object
    this.updateFeeds([feedObject]);
  };

  setLocalStreams = (streams) => {
    this.localStreams = streams;

    // Match saved feeds with local streams
    const activeFeeds = this.matchSavedFeeds();

    // Call the shared updateFeeds function with the array of active feeds
    this.updateFeeds(activeFeeds);
  };

  // Function to match saved feeds with local streams
  matchSavedFeeds = () => {
    const savedFeeds = JSON.parse(localStorage.getItem('activeFeeds')) || []; // Load saved feeds from localStorage

    const activeFeeds = [];

    // Step 2: Loop over the savedFeeds and update matching streams in localStreams
    savedFeeds.forEach(savedFeed => {
      const localStream = this.localStreams.find(stream => stream.deviceId === savedFeed.deviceId);
      if (localStream) {

        // Create a new feedObject based on the localStream and savedFeed data
        const streamObject = {
          ...localStream,
          cameraType: savedFeed.cameraType, // Initially null
        };

        let mediaObject = new MediaObject(streamObject);

        // Push the updated feedObject into the activeFeeds array
        activeFeeds.push(mediaObject);
      }
    });

    // Return the array of active feeds
    return activeFeeds;
  };


  // Helper function to generate a unique ID
  generateUniqueId = () => {
    return `feed_${Math.random().toString(36).substr(2, 9)}`;
  };

  // Shared function to handle adding/removing feeds and updating the state/context
  updateFeeds = (newFeeds) => {
    // console.log('activating feeds: ', newFeeds);
    const { removeFeedById, activateFeeds } = this.context;

    // const filteredNewFeeds = newFeeds.filter(feed => {
    //   if (feed.remove) {
    //     // Pass feed.id to removeFeedById()
    //     removeFeedById(feed.id);
    //     return false; // Remove this feed from the newFeeds array
    //   }
    //   return true; // Keep feeds where remove !== true
    // });

    // Remove old feeds, activate new feeds
    this.setState((prevState) => {

      // Filter out old feeds of the same cameraType(s) from activeFeeds
      // These feeds will not be removed
      const filteredFeeds = prevState.activeFeeds.filter(
        feed => !newFeeds.some(newFeed => newFeed.cameraType === feed.cameraType)
      );

      // Deactivate any previous feeds for the same cameraType(s)
      prevState.activeFeeds.forEach(feed => {
        if (newFeeds.some(newFeed => newFeed.cameraType === feed.cameraType)) {
          removeFeedById(feed.id); // Remove the old feed from the context provider
        }
      });

      // Activate the new feeds in the context provider
      // console.log('The actual feeds: ', newFeeds)
      activateFeeds(newFeeds);

      // Add the new feeds with unique IDs and update the state
      const updatedFeeds = [...filteredFeeds, ...newFeeds];

      // Grab audio from local storage if it exists and addto updated feeds
      const audioFeeds = JSON.parse(localStorage.getItem('activeFeeds')) || [];
      const theFeeds = [...updatedFeeds, ...audioFeeds.filter(feed => feed.type === 'audio')];

      // Save the updated feeds to localStorage
      localStorage.setItem('activeFeeds', JSON.stringify(theFeeds));

      return { activeFeeds: updatedFeeds, showModal: false };
    });
  };

  resetDefaultViews = () => {
    const { removeFeedById } = this.context;

    this.setState((prevState) => {
      // console.log('Resetting all views, stopping streams, and clearing feeds:', prevState.activeFeeds);

      // Iterate over each active feed and stop its stream
      prevState.activeFeeds.forEach(feed => {
        // Remove the feed from the context provider
        removeFeedById(feed.id);
      });

      // Save the empty/null feeds to localStorage
      localStorage.removeItem('activeFeeds');  // Clears previous value
      localStorage.setItem('activeFeeds', JSON.stringify([]));

      // Return the updated state with no active feeds
      return { activeFeeds: [] };
    });

    this.setState({ resetView: true }, () => {
      this.setState({ resetView: false })
    })
  };


  // Render each video stream
  renderVideoFeed = (cameraType) => {
    const activeFeed = this.state.activeFeeds.find(feed => feed.cameraType === cameraType);

    return activeFeed ? (
      <Media mediaObject={activeFeed} renderControlIcons={false} />
      // <video
      //   ref={activeFeed.setRef}
      //   autoPlay
      //   muted
      //   style={{ width: '100%', height: '100%' }}
      // />
    ) : (
      <div style={{ width: '100%', height: '100%', backgroundColor: '#000' }} />
    );
  };

  render() {
    const { showModal, selectedCamera } = this.state;

    // console.log('this.context.players: ', this.context.players)


    return (
      <div {...styles.outerContainer}>
        <div {...styles.innerContainer}>
          <div {...styles.columnHeader}>Comms</div>

          {/* Call CameraFeedCollector to grab all local camera feeds */}
          <CameraFeedCollector setLocalStreams={this.setLocalStreams} />

          <div {...styles.rowStyle}>
            <GoLiveButton />
          </div>

          <div {...styles.rowStyle}>
            Connected Peers: {this.context.peerCount}
          </div>

          <div {...styles.rowStyle}>
            <span {...styles.linkStyle} onClick={this.resetDefaultViews}>Reset Default Views</span>
          </div>

          <div {...styles.rowStyle}>
            <div>Live Broadcast:</div>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              {/* Camera feed placeholders */}
              {this.cameraFeeds.map((cameraType) => (
                <div
                  key={cameraType}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.openMediaSelector(cameraType)}
                >
                  {/* Camera Name */}
                  <div
                    style={{
                      textAlign: 'center',
                      color: '#fff',
                      marginBottom: '5px',
                      width: '120px',
                      padding: '5px 0',
                    }}
                  >
                    {cameraType.toUpperCase()}
                  </div>

                  {/* Video Thumbnail */}
                  <div
                    style={{
                      width: '120px',
                      height: '90px',
                      backgroundColor: '#000',
                      border: '1px solid white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {this.renderVideoFeed(cameraType)}

                  </div>
                </div>
              ))}
            </div>

            <AudioSelector resetView={this.state.resetView} styles={styles} />

            <ScreenshareSelector styles={styles} />

          </div>
        </div>

        <div style={{ flexGrow: 1, minWidth: '400px' }}>
          <div style={{ backgroundColor: '#737373', color: '#fff', padding: '10px 20px', borderRadius: '20px', fontWeight: 'bold' }}>Live Feeds</div>
          <div {...styles.rowStyle}>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              {this.context.players.map((p, i) => {
                return <div key={`mediaObjects-${i}`}>
                  <Media mediaObject={p} renderControlIcons={true} />
                </div>
              })}
            </div>
          </div>
          <div>

            {/* <TacView /> */}

          </div>
        </div>

        {/* Modal for selecting media */}
        {showModal && (
          <WebCamSelector
            localStreams={this.localStreams} // Pass the local streams from CameraFeedCollector
            cameraType={selectedCamera} // Pass the selected camera type (faceview, sideview, etc.)
            onSelectFeed={this.onSelectFeed} // Pass the feed selection handler
            closeModal={this.closeSelector} // Pass the close modal handler
          />
        )}

      </div>
    );
  }
}

const styles = {
  outerContainer: style({
    margin: '50px',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  innerContainer: style({
    width: '30%',
    minWidth: '400px',
    marginRight: '20px'
  }),
  columnHeader: style({
    backgroundColor: '#737373',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '20px',
    fontWeight: 'bold',
    width: '200px'
  }),
  rowStyle: style({
    margin: '20px 0'
  }),
  linkStyle: style({
    textDecoration: 'none',
    color: '#007bff',
    fontSize: '1rem',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  })
};

export default EITDServices;
