/*****************************************************
 * NewSite.Com <Render React to DOM>
 * John Minton <cjohnweb@gmail.com>
 *****************************************************/

import React from 'react';
import { createRoot } from 'react-dom/client';
import Main from './src/main.jsx';
import CommsContextProvider from './src/CommsContext.jsx';
// import registerServiceWorker from './registerServiceWorker';

window.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <CommsContextProvider>
      <Main />
    </CommsContextProvider>
  );
});

// registerServiceWorker();
