import React, { createContext, Component } from 'react';
import MediaObject from './mediaObject'
import createSocket from './socketSingleton';

import { monitorAndFallback_fiveLevels } from './/monitorAndFallback';

// Configure monitor and fallback mechanism
// This controls the bitrate of videos based on available bandwidth.
// It can be used to keep audio and drop video when bandwidth is too low, for example
function monitorAndFallback(peerConnection) {
  monitorAndFallback_fiveLevels.call(peerConnection);
}

// Monkey patch the socket.emit function to add console logging.
// const originalEmit = socket.emit;
// socket.emit = function (event, ...args) {
//   console.log('emitting ', event)
//   originalEmit.apply(socket, [event, ...args]);
// };

export const CommsContext = createContext();

class CommsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      players: [],
      video: true,
      roomName: '',
      peerCount: 0,
      initializedRTC: false,
      waitingForPeer: false,
      joinedRoom: false,
      peerJoined: false,
      activeFeeds: [],
      activeAudio: null,
      bandwidth: {},
      authenticated: false
    };

    this.configuration = {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' }
      ]
    };

    this.socket = null;
    this.localStreams = [];
    this.timeouts = {}
    this.peers = {};

    this.MONITOR_BANDWIDTH = true;
  }

  async componentDidMount() {
    // console.log('component did mount')
    // Check the saved state from localStorage and call setGoLive with the stored value
    this.socket = await createSocket();
    const goLive = localStorage.getItem('goLive') === 'true' && this.state.authenticated;
    if (goLive) {
      setTimeout(() => {
        this.setGoLive(goLive);
      }, 500)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.roomName !== this.state.roomName) {
      // console.log('component did update')
      if (this.state.joinedRoom) {
        console.log('Now connected to the room');
      } else {
        console.log('Disconnected from the room');
      }
    }
  }

  setAuthentication = async (auth) => {
    console.log('Auth')
    this.setState({ authenticated: auth });
  };

  setGoLive = async (goLive) => {
    console.log('set Go Live')

    if (goLive) {
      // Call the connect function if the state is set to true
      this.connect();
    } else {
      // Call the disconnect function if the state is set to false
      this.disconnect();
    }
  };

  connect = async () => {
    console.log('connect')

    // Check if already connected to avoid redundant calls
    if (this.state.joinedRoom) return;

    // Join the WebSocket room and set up WebRTC connections
    this.setState({ joinedRoom: true }, () => {
      console.log('Connecting...');
      this.joinRoom();
    });
  };

  disconnect = () => {
    console.log('disconnect: ', this.state.joinedRoom)

    if (this.state.joinedRoom) {
      console.log('Disconnecting....');

      // Gracefully disconnect WebRTC peers
      Object.keys(this.peers).forEach((peerId) => {
        this.cleanupPeer(peerId); // Clean up each peer connection
      });

      // Stop local streams transmission but keep local playback active
      this.localStreams.forEach((streamObj) => {
        streamObj.stream.getTracks().forEach(track => {
          if (track.readyState === 'live') {
            track.stop(); // Stop track transmission without removing local playback
          }
        });
      });

      // Disconnect from the WebSocket room
      this.socket.emit('leaveRoom', this.state.roomName);
      this.setState({ joinedRoom: false, roomName: '' }, () => {
        console.log('Disconnected.');
      });
    } else {
      console.log('Already Disconnected');
    }
  };

  joinRoom = async () => {
    let roomName = 'mintontechnologies';
    const chatRoomName = `chat-${roomName.trim()}`;
    console.log('Joining Room: ', chatRoomName);

    // Wait for socket to be created and connected
    this.socket = await createSocket();
    await new Promise((resolve) => {
      if (this.socket.connected) {
        resolve();
      } else {
        this.socket.on('connect', () => {
          resolve();
        });
      }
    });

    this.setupSocketListeners(chatRoomName);
    this.setState({ roomName: chatRoomName });
  };

  // Check if both us and the peer is ready.
  checkReady = (id) => {
    console.log('checkReady: ', id, this.peers);
    if (this.peers[id]?.ready) {
      clearTimeout(this.peers[id].timeout);
      this.updateOnScreenPlayers();
      this.createOffer(id);
    } else {
      this.timeouts[id] = setTimeout(() => {
        this.checkReady(id)
      }, 1000)
    }
  }

  setupSocketListeners = (chatRoomName) => {

    this.socket.emit('joinRoom', chatRoomName);

    this.socket.on('selfJoined', ({ id }) => {
      // console.log('selfJoined: ', id);

      this.setState({ id });
      this.socket.emit('requestParticipants');

      this.socket.on('updateParticipants', (peers) => {
        console.log('new peers updated: ', peers)
        this.setState({ peerCount: Object.keys(peers).length });

        const newPeers = peers.filter(pid => {
          return !this.peers.hasOwnProperty(pid) && pid !== id; // filter out our own id
        });

        newPeers.forEach((peerId) => {
          if (!this.peers[peerId]) {
            this.peers[peerId] = {
              id: peerId,
              isCaller: peerId < this.state.id,
              timeout: null,
              ready: false,
              metaData: (this.localStreams || []).map((localStream) => {
                return {
                  id: localStream.id,
                  peerId: peerId,
                  stream_id: localStream.stream.id,
                  cameraType: localStream.cameraType,
                  audioType: localStream.audioType,
                  videoType: localStream.videoType,
                  mediaType: localStream.mediaType,
                }
              }),
              peerReady: false,
              connectionState: null,
              peerConnection: new RTCPeerConnection(this.configuration),
              streamMetaData: [],
              remoteTracks: [],
              negotiationInProgress: false
            };

            console.log('this.peers[peerId]:', this.peers[peerId])

            this.socket.emit('streamMetaData', { remoteStreams: this.peers[peerId].metaData, from: this.state.id, to: peerId });
            this.initializeWebRTC(peerId);
          }

        });
      });

      this.socket.on('streamMetaData', ({ remoteStreams, id }) => {
        console.log('Received remoteStreams:', Array.isArray(remoteStreams), remoteStreams);
        if (!Array.isArray(remoteStreams)) {
          console.warn('Expected an array, but received an object WYH?!! no make sense:', remoteStreams);
          remoteStreams = [remoteStreams];
        }
        console.log('remoteStreams: ', remoteStreams)
        this.peers[id].streamMetaData = [this.peers[id].streamMetaData, ...remoteStreams];
        this.peers[id].receivedMetaData = true;
        this.updateOnScreenPlayers();
      })

      this.socket.on('peerReady', (id) => {
        // console.log('Received peer ready');
        this.checkReady(id);
      });

      this.socket.on('offer', async ({ offer, id }) => {
        try {
          // Set the remote description and create an answer if we're in the correct state
          if (this.peers[id].peerConnection.signalingState === 'stable' || this.peers[id].peerConnection.signalingState === 'have-local-offer') {
            await this.peers[id].peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await this.peers[id].peerConnection.createAnswer();
            await this.peers[id].peerConnection.setLocalDescription(answer);

            // Send the answer back to the offerer
            this.socket.emit('answer', { answer, from: this.state.id, to: id });
          } else {
            console.warn(`Cannot set remote description for offer, signaling state is ${this.peers[id].peerConnection.signalingState}`);
          }
        } catch (error) {
          console.error('Error handling offer:', error);
        }
      });


      this.socket.on('answer', async ({ answer, id }) => {
        // console.log('Received answer: ', id, this.peers[id].peerConnection.signalingState, answer);
        if (this.peers[id].peerConnection.signalingState === 'have-local-offer') {
          await this.peers[id].peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
          // console.log('Signaling State after setRemoteDescription (answer): ', this.peers[id].peerConnection.signalingState);
        } else {
          console.warn(`Cannot set remote description for answer, signaling state is ${this.peers[id].peerConnection.signalingState}. Only 1 client should send an offer. Double check who is sending offers.`);
        }
      });

      this.socket.on('ice-candidate', async ({ candidate, id }) => {
        try {
          await this.peers[id].peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
        } catch (error) {
          console.error('Error adding received ICE candidate:', error);
        }
      });

      this.socket.on('peerDisconnected', (id) => {
        console.log('peerDisconnected')
        this.cleanupPeer(id);
      });

    });
  };


  createOffer = async (id) => {
    if (!this.peers[id].isCaller) {
      const offer = await this.peers[id].peerConnection.createOffer();
      await this.peers[id].peerConnection.setLocalDescription(offer);
      // console.log('CreateOffer: Sending Offer');
      this.socket.emit('offer', { offer, from: this.state.id, to: id });
    }
  };

  async initializeWebRTC(id) {
    console.log(`initializeWebRTC (remote peerid: ${id})`);

    try {

      // console.log('Attaching tracks to send')
      // Add each local track to the peer connection
      this.localStreams.forEach(localStream => {
        localStream.stream.getTracks().forEach(track => {
          const existingSender = this.peers[id].peerConnection.getSenders().find(sender => sender.track === track);

          if (existingSender) {
            // Track already exists, use replaceTrack() if you need to update the sender's track
            console.log('Replacing existing track for sender');
            // existingSender.replaceTrack(track);
          } else {
            // Add the track if it's not already being sent
            console.log('Adding new track to peer connection');
            this.peers[id].peerConnection.addTrack(track, localStream.stream);
            if (this.MONITOR_BANDWIDTH) {
              this.monitorLocalBandwidth(id, 'local', track);
            }
          }

        });
      });

      // Handle ICE candidates
      this.peers[id].peerConnection.onicecandidate = event => {
        if (event.candidate) {
          // console.log(`Sending ice-candidate to peer: `, id);
          this.socket.emit('ice-candidate', { candidate: event.candidate, from: this.state.id, to: id });
        }
      };


      /**************************************************
      Manage Individual tracks from peers. A track is a 
      single audio or video stream. Video with audio tracks
      get separated in the process of sending over WebRTC.
      We do not worry about recombining them here. ontrack
      and onremovetrack will manage the active streams 
      coming in from peers.
  
      this.peers[id].remoteTracks will contain the list of
      all tracks from each peer. It is the "source of truth"
      
      /***************************************************/
      // Handle incoming tracks (video and audio)
      this.peers[id].peerConnection.ontrack = event => {
        console.log('RECEIVED REMOTE TRACK')
        const stream = event.streams[0];

        stream.getTracks().forEach(track => {
          // Check if the track is already present in remoteTracks based on track.id
          const isTrackAlreadyAdded = this.peers[id].remoteTracks.some(
            existingTrack => existingTrack.track.id === track.id
          );

          // Only add the track if it's not already in remoteTracks
          if (!isTrackAlreadyAdded) {
            // console.log('incoming track: ', track);
            this.peers[id].remoteTracks.push({
              track,
              stream_id: stream.id,  // Keep the stream_id for identification
              kind: track.kind,     // Track kind (audio/video)
            });

            // Call monitorLocalBandwidth for remote streams
            if (this.MONITOR_BANDWIDTH) {
              this.monitorLocalBandwidth(id, 'remote', track);
            }
          }
        });

        // Update the players on the screen after filtering duplicates
        this.peers[id].receivedTracks = true;
        this.updateOnScreenPlayers();
      };

      // Handle the removal of tracks (this fires when the peer stops the track)
      this.peers[id].peerConnection.onremovetrack = event => {
        const removedTrack = event.track;

        // Remove the track from remoteTracks
        this.peers[id].remoteTracks = this.peers[id].remoteTracks.filter(trackObj => trackObj.track !== removedTrack);
        // Update the players on the screen
        this.peers[id].receivedTracks = true;
        this.updateOnScreenPlayers();
      };


      this.peers[id].peerConnection.onnegotiationneeded = async () => {
        try {
          console.log(`Negotiation needed for peer: ${id}`);

          if (this.peers[id].negotiationInProgress) {
            console.log(`Negotiation already in progress for peer: ${id}`);
            return;
          }

          if (!this.peers[id].isCaller) {
            console.log(`Peer ${id} is not the caller, skipping negotiation.`);
            return;
          }

          console.log(`Peer ${id} signaling state before creating offer: ${this.peers[id].peerConnection.signalingState}`);

          this.peers[id].negotiationInProgress = true;

          if (this.peers[id].peerConnection.signalingState === 'stable') {
            console.log(`Creating offer for peer ${id}`);
            await this.createOffer(id);
          } else {
            console.warn(`Skipping offer creation for peer ${id}, signaling state is ${this.peers[id].peerConnection.signalingState}`);
          }
        } catch (error) {
          console.error(`Error during negotiation for peer ${id}: `, error);
        } finally {
          this.peers[id].negotiationInProgress = false;
        }
      };

      // Handle connection state changes
      this.peers[id].peerConnection.oniceconnectionstatechange = () => {
        const state = this.peers[id].peerConnection.iceConnectionState;
        // console.log(`ICE connection state changed to ${state} for peer ${id}`);
        if (state === 'disconnected' || state === 'failed') {
          this.attemptReconnect(id);
        }
      };

      // Handle signaling state changes
      this.peers[id].peerConnection.onsignalingstatechange = () => {
        // console.log('onsignalingstatechange: ', this.peers[id].peerConnection.signalingState);

        if (this.peers[id].peerConnection.signalingState === 'closed') {
          console.log(`Peer connection closed for peer ${id}. Cleaning up.`);
          this.cleanupPeer(id);
        }
      };

      // Emit peerReady event after WebRTC setup is complete
      this.peers[id].ready = true;
      // console.log('sending peerReady');
      this.socket.emit('peerReady', { from: this.state.id, to: id });

      // Start monitoring the connection for this peer
      this.peers[id].monitorInterval = setInterval(() => {
        monitorAndFallback(this.peers[id].peerConnection);
      }, 5000); // Monitor every 5 seconds

    } catch (error) {
      console.error('Error initializing WebRTC:', error);
    }
  }

  renegotiateConnection = async (id) => {
    try {
      console.log(`Renegotiating connection with peer: ${id}`);
      const offer = await this.peers[id].peerConnection.createOffer();
      await this.peers[id].peerConnection.setLocalDescription(offer);

      // Send the new offer to the peer
      this.socket.emit('offer', { offer, from: this.state.id, to: id });
    } catch (error) {
      console.error('Error during renegotiation:', error);
    }
  };


  monitorLocalBandwidth = (id, key, streamOrTrack) => {
    let previousBytes = 0;
    let previousTimestamp = 0;
    const peerConnection = this.peers[id].peerConnection; // Simplified for first peer

    const monitor = (peerConnection) => {


      // Ensure that we're passing a MediaStreamTrack
      const tracks = streamOrTrack instanceof MediaStream ? streamOrTrack.getTracks() : [streamOrTrack];

      tracks.forEach(track => {
        peerConnection.getStats(track).then(stats => {
          stats.forEach(report => {
            if ((report.type === 'outbound-rtp' && key === 'local') || (report.type === 'inbound-rtp' && key === 'remote')) {
              const currentBytes = key === 'local' ? report.bytesSent : report.bytesReceived;
              const currentTimestamp = report.timestamp || 0;

              let bitrate = 0;
              if (previousTimestamp && previousBytes) {
                const bytesDifference = currentBytes - previousBytes;
                const timeDifference = (currentTimestamp - previousTimestamp) / 1000; // Convert ms to seconds

                if (timeDifference > 0) {
                  bitrate = (bytesDifference * 8) / timeDifference; // Convert bytes to bits
                }
              }

              previousBytes = currentBytes;
              previousTimestamp = currentTimestamp;

              // Update the state with the calculated bitrate and total bytes
              this.updateBandwidthUI(key, track.id, bitrate.toFixed(2), currentBytes);
            }
          });
        });
      });

      // Continue monitoring every second
    };

    peerConnection.monitorBandwidthTimeout = setInterval(monitor(peerConnection), 1000);
  };

  // Helper function to convert bytes to a human-readable format (b, kb, mb)
  formatBytes = (bytes) => {
    if (bytes >= 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return bytes + ' B';
    }
  };

  // Helper function to convert bits to a human-readable format (b, kb, mb)
  formatBits = (bits) => {
    if (bits >= 1024 * 1024) {
      return (bits / (1024 * 1024)).toFixed(2) + ' Mbps';
    } else if (bits >= 1024) {
      return (bits / 1024).toFixed(2) + ' Kbps';
    } else {
      return bits + ' bps';
    }
  };

  updateBandwidthUI = (key, trackId, bitrate, totalBytes) => {
    let bandwidth = { ...this.state.bandwidth };

    // Create the key if it doesn't exist
    if (!bandwidth[key]) {
      bandwidth[key] = {};
    }

    // Format totalBytes (B, KB, MB) and bitrate (bps, Kbps, Mbps)
    const formattedBytes = this.formatBytes(totalBytes);
    const formattedBitrate = this.formatBits(bitrate);

    // Store the bandwidth data under local or remote key
    bandwidth[key][trackId] = {
      key,
      trackId,
      bitrate: formattedBitrate,
      totalBytes: formattedBytes,
    };

    this.setState({ bandwidth });
    // console.log(`Key: ${key}, Track: ${trackId}, Bitrate: ${bitrate}, Total Bytes: ${totalBytes}`);
  };

  attemptReconnect = (peerId) => {
    const maxRetries = 3;
    let attempts = 0;

    const tryReconnect = () => {
      if (attempts < maxRetries) {
        console.log(`Attempting to reconnect... (${attempts + 1}/${maxRetries})`);

        this.peers[peerId].peerConnection.restartIce();

        // Increment attempts counter
        attempts++;

        // Check if reconnection was successful after some delay
        setTimeout(() => {
          if (this.peers[peerId].peerConnection.iceConnectionState === 'connected') {
            console.log('Reconnected successfully');
          } else {
            tryReconnect(); // Retry
          }
        }, 3000); // Wait 3 seconds before checking the connection status
      } else {
        console.error('Reconnection failed after maximum attempts');
        this.cleanupPeer(peerId); // Clean up if all retries fail
      }
    };

    tryReconnect();
  };


  cleanupPeer = (id) => {
    if (this.peers[id]) {
      console.log(`Cleaning up peer: ${id}`);

      if (this.peers[id].monitorInterval) {
        clearInterval(this.peers[id].monitorInterval);
        delete this.peers[id].monitorInterval; // Optionally remove the property after clearing the interval
      }

      // Close the peer connection
      if (this.peers[id].peerConnection) {
        this.peers[id].peerConnection.close();
      }

      // Clear timeouts if any
      clearTimeout(this.peers[id].peerConnection.monitorBandwidthTimeout)
      clearTimeout(this.timeouts[id]);
      delete this.timeouts[id];

      // Remove the peer from the list
      delete this.peers[id];

      // console.log('Players supposed to be removed: ', id, players)
      // Remove the peer's video element from the players array
      let players = this.state.players.filter(player => player.peerId !== id)


      this.setState({ players }, () => {
        this.updateOnScreenPlayers();
      });
    }
  }

  updateOnScreenPlayers = () => {
    // Step 1: Pull the current players from state
    let players = [...this.state.players];
    console.log('*** UPDATE ONSCREEN PLAYERS', players)



    /**
     * 
     * THIS NEEDS TO WORK CORRECTLY, AND RELIABLY.
     * 
     * When client a joins, no problem.
     * When client B joins, they see client A media.
     * But Client A does not see client B media.
     * Client C joins, and sees Cliet A and B media, but client A takes a Dump again.
     * 
     * The F.
     * 
     * only 1 camera issue whnere there should be 3.
     * We need to make sure that there is a feed for every meta data, make sure every metaData has a feed.
     * Only comine audio and video when metaData is screenshare type
     * 
     * // Addresses audio not displaying issue
     * Ensure meta data comes in before feed does.
     */



    // Step 2: Loop through each peer in this.peers
    Object.keys(this.peers).forEach(peerId => {

      console.log("update players checking flags: ", peerId, this.peers[peerId].receivedMetaData, this.peers[peerId].receivedTracks)

      if (this.peers[peerId].receivedMetaData && this.peers[peerId].receivedTracks) {
        this.peers[peerId].receivedMetaData = false;
        this.peers[peerId].receivedTracks = false;
      }

      // Step 3: Build players with metadata and references using Object.entries to get both stream_id and mediaStream
      this.peers[peerId].streamMetaData.forEach((meta) => {

        // Does it exist in the players object already? If so, skip it.

        let tracks = [];

        if (meta.cameraType && meta.videoType == 'webcam' && !players.some((player) => (player.cameraType === meta.cameraType && player.stream_id === meta.stream_id))) {
          tracks = this.peers[peerId].remoteTracks.filter((track) => {
            return track.stream_id === meta.stream_id;
          })
        } else if (meta.videoType == 'screenshare' && !players.some((player) => (player.videoType === meta.videoType && player.stream_id === meta.stream_id))) {
          tracks = this.peers[peerId].remoteTracks.filter((track) => {
            return track.stream_id === meta.stream_id;
          })
        } else if (meta.audioType == 'standAlone' && !players.some((player) => (player.audioType === meta.audioType && player.stream_id === meta.stream_id))) {
          tracks = this.peers[peerId].remoteTracks.filter((track) => {
            return track.stream_id === meta.stream_id;
          })
        }

        if (tracks.length > 0) {
          const newStream = new MediaStream();

          tracks.forEach((track) => {
            newStream.addTrack(track.track); // Add each track (audio/video) to the MediaStream
          });

          let mediaObject = new MediaObject({
            ...meta,
            peerId: peerId,
            isLocal: false,
            stream_id: tracks[0].stream_id,
            type: newStream.getVideoTracks().length > 0 ? 'video' : 'audio',
            stream: newStream
          })
          players.push(mediaObject);
        }

      });

    })

    // Step 4: Remove players that no longer correspond to an existing peer
    players = players.filter(player => {
      return Object.keys(this.peers).some((peerId) => (player.peerId === peerId))
    });

    console.log('Should see my players: ', players)

    // Step 5: Update the state with the new list of players
    this.setState({ players });
  };

  // Get Feed sources from MediaSelector
  activateFeeds = (feeds) => {

    // loop through each peer
    Object.keys(this.peers).forEach((id) => {

      feeds.forEach(feed => {
        console.log('FEED: ', feed)
        // Send new MetaData
        let metaData = {
          stream_id: feed.stream.id,
          cameraType: feed.cameraType,
          audioType: feed.audioType,
          videoType: feed.videoType,
          mediaType: feed.mediaType,
        }

        console.log('For each peer id ', id)
        this.socket.emit('streamMetaData', { remoteStreams: metaData, from: this.state.id, to: id });

        // Send the feed
        console.log('get tracks: ')
        feed.stream.getTracks().forEach(track => {
          console.log('\t -Sending Track: ', track)

          const existingSender = this.peers[id].peerConnection.getSenders().find(sender => sender.track === track);
          if (existingSender) {
            // Track already exists, use replaceTrack() if you need to update the sender's track
            console.log('Replacing existing track for sender');
            existingSender.replaceTrack(track);
          } else {
            // Add the track if it's not already being sent
            console.log('Adding new track to peer connection');
            this.peers[id].peerConnection.addTrack(track, feed.stream);

            // Trigger renegotiation if the peer connection is already connected
            if (this.peers[id].peerConnection.connectionState === 'connected') {
              this.renegotiateConnection(id);
            }

            if (this.MONITOR_BANDWIDTH) {
              this.monitorLocalBandwidth(id, 'local', track);
            }
          }

        });
      })
      // console.log('Activate Feed: ', feed);
    });

    feeds.forEach(feed => {
      this.localStreams.push(feed);
    });

    console.log('Updated localStreams:', this.localStreams);
  };



  removeFeedById = (uniqueId) => {
    const feedIndex = this.localStreams.findIndex(feed => feed.id === uniqueId);

    if (feedIndex !== -1) {
      const feed = this.localStreams[feedIndex];

      // Stop all tracks in the stream
      if (feed && feed.stream) {
        feed.stream.getTracks().forEach(track => track.stop());
      }

      // Optionally remove the feed from WebRTC peers (if applicable)
      Object.keys(this.peers).forEach(peerId => {
        const peer = this.peers[peerId];
        if (peer) {
          // Remove tracks related to the stream from the peer connection
          peer.peerConnection.getSenders().forEach(sender => {
            if (sender.track && sender.track.id === feed.stream.id) {
              peer.peerConnection.removeTrack(sender);
            }
          });
        }
      });

      // Remove the feed from the localStreams array
      this.localStreams.splice(feedIndex, 1);

      console.log(`Feed with ID ${uniqueId} has been stopped and removed.`);
    } else {
      console.warn(`Feed with ID ${uniqueId} not found in localStreams.`);
    }
  };

  activateAudio = (audio) => {
    // console.log('audio: ', audio);

    // Remove the old audio stream if it exists
    this.localStreams = this.localStreams.filter(stream => stream.type !== 'audio');

    if (audio) {
      // Add the new audio stream
      this.localStreams.push({
        mediaType: 'audio',
        audioType: audio.audioType,
        videoType: audio.videoType,
        stream: audio.stream,
        ref: React.createRef(),
      });
    }

    // Update the state with the new audio feed
    this.setState({ activeAudio: audio });
  }

  render() {
    return (
      <CommsContext.Provider value={{
        localStreams: this.localStreams,
        players: this.state.players,
        joinRoom: this.joinRoom,
        activateFeeds: this.activateFeeds,
        removeFeedById: this.removeFeedById,
        activateAudio: this.activateAudio,
        bandwidth: this.state.bandwidth,
        peerCount: this.state.peerCount,
        setGoLive: this.setGoLive,
        setAuthentication: this.setAuthentication
      }}>
        {this.props.children}
      </CommsContext.Provider>
    );
  }
}
export default CommsContextProvider;

