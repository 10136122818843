// Constants for bandwidth thresholds and bitrates
const VERY_LOW_BANDWIDTH_THRESHOLD = 100000; // e.g., 100 kbps
const LOW_BANDWIDTH_THRESHOLD = 300000;     // e.g., 300 kbps
const MEDIUM_BANDWIDTH_THRESHOLD = 800000;  // e.g., 800 kbps
const HIGH_BANDWIDTH_THRESHOLD = 1500000;   // e.g., 1.5 Mbps
const VERY_HIGH_BANDWIDTH_THRESHOLD = 3000000; // e.g., 3 Mbps

const VERY_LOW_BITRATE = 50000;   // e.g., 50 kbps
const LOW_BITRATE = 150000;      // e.g., 150 kbps
const MEDIUM_BITRATE = 500000;   // e.g., 500 kbps
const HIGH_BITRATE = 1000000;    // e.g., 1 Mbps
const VERY_HIGH_BITRATE = 2500000; // e.g., 2.5 Mbps

// Option to cut video if bandwidth is too low
const CUT_VIDEO_ON_LOW_BANDWIDTH = false; // Set to false if you don't want to cut video

// Utility function to get current bandwidth (this is a simplified example)
export function getCurrentBandwidth(peerConnection) {
  return peerConnection.getStats(null).then(stats => {
    let bandwidthEstimate = null;

    stats.forEach(report => {
      if (report.type === 'candidate-pair' && report.state === 'succeeded') {
        bandwidthEstimate = report.availableOutgoingBitrate;
      }
    });

    return bandwidthEstimate || 0;
  });
}

// Utility function to handle video cut-off based on bandwidth
function handleVideoCutoff(sender, currentBandwidth) {
  if (CUT_VIDEO_ON_LOW_BANDWIDTH && currentBandwidth < VERY_LOW_BANDWIDTH_THRESHOLD) {
    if (sender.track.enabled) {
      sender.track.enabled = false; // Disable the video track
      console.log('Disabling video track due to extremely low bandwidth');
    }
  } else if (currentBandwidth >= LOW_BANDWIDTH_THRESHOLD) {
    if (!sender.track.enabled) {
      sender.track.enabled = true;
      console.log('Re-enabling video track due to improved bandwidth');
    }
  }
}

// 2-Level Bandwidth Monitoring (High/Low)
export function monitorAndFallback_twoLevels(peerConnection) {

  if (!peerConnection || typeof peerConnection.getSenders !== 'function') {
    console.log('PeerConnection or getSenders() is not available, skipping monitorAndFallback.');
    return;
  }

  const senders = peerConnection.getSenders();

  senders.forEach(sender => {
    if (sender.track && sender.track.kind === 'video') {
      const currentBandwidth = getCurrentBandwidth(peerConnection);
      const parameters = sender.getParameters();

      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }

      handleVideoCutoff(sender, currentBandwidth);

      if (currentBandwidth >= LOW_BANDWIDTH_THRESHOLD && currentBandwidth < HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = LOW_BITRATE;
        console.log('Reduced video bitrate to:', LOW_BITRATE);
      } else if (currentBandwidth >= HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = HIGH_BITRATE;
        console.log('Increased video bitrate to:', HIGH_BITRATE);
      }

      sender.setParameters(parameters)
        .then(() => console.log('Updated video bitrate'))
        .catch(e => console.error('Error updating video bitrate:', e));
    }
  });
}

// 3-Level Bandwidth Monitoring (Low/Medium/High)
export function monitorAndFallback_threeLevels(peerConnection) {

  if (!peerConnection || typeof peerConnection.getSenders !== 'function') {
    console.log('PeerConnection or getSenders() is not available, skipping monitorAndFallback.');
    return;
  }

  const senders = peerConnection.getSenders();

  senders.forEach(sender => {
    if (sender.track && sender.track.kind === 'video') {
      const currentBandwidth = getCurrentBandwidth(peerConnection);
      const parameters = sender.getParameters();

      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }

      handleVideoCutoff(sender, currentBandwidth);

      if (currentBandwidth < LOW_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = LOW_BITRATE;
        console.log('Setting video bitrate to low:', LOW_BITRATE);
      } else if (currentBandwidth >= LOW_BANDWIDTH_THRESHOLD && currentBandwidth < HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = MEDIUM_BITRATE;
        console.log('Setting video bitrate to medium:', MEDIUM_BITRATE);
      } else if (currentBandwidth >= HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = HIGH_BITRATE;
        console.log('Setting video bitrate to high:', HIGH_BITRATE);
      }

      sender.setParameters(parameters)
        .then(() => console.log('Updated video bitrate'))
        .catch(e => console.error('Error updating video bitrate:', e));
    }
  });
}

// 5-Level Bandwidth Monitoring (Very Low/Low/Medium/High/Very High)
export function monitorAndFallback_fiveLevels(peerConnection) {

  if (!peerConnection || typeof peerConnection.getSenders !== 'function') {
    console.log('PeerConnection or getSenders() is not available, skipping monitorAndFallback.');
    return;
  }
  
  const senders = peerConnection.getSenders();

  senders.forEach(sender => {
    if (sender.track && sender.track.kind === 'video') {
      const currentBandwidth = getCurrentBandwidth(peerConnection);
      const parameters = sender.getParameters();

      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }

      handleVideoCutoff(sender, currentBandwidth);

      if (currentBandwidth < VERY_LOW_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = VERY_LOW_BITRATE;
        console.log('Setting video bitrate to very low:', VERY_LOW_BITRATE);
      } else if (currentBandwidth >= VERY_LOW_BANDWIDTH_THRESHOLD && currentBandwidth < LOW_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = LOW_BITRATE;
        console.log('Setting video bitrate to low:', LOW_BITRATE);
      } else if (currentBandwidth >= LOW_BANDWIDTH_THRESHOLD && currentBandwidth < HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = MEDIUM_BITRATE;
        console.log('Setting video bitrate to medium:', MEDIUM_BITRATE);
      } else if (currentBandwidth >= HIGH_BANDWIDTH_THRESHOLD && currentBandwidth < VERY_HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = HIGH_BITRATE;
        console.log('Setting video bitrate to high:', HIGH_BITRATE);
      } else if (currentBandwidth >= VERY_HIGH_BANDWIDTH_THRESHOLD) {
        parameters.encodings[0].maxBitrate = VERY_HIGH_BITRATE;
        console.log('Setting video bitrate to very high:', VERY_HIGH_BITRATE);
      }

      sender.setParameters(parameters)
        .then(() => console.log('Updated video bitrate'))
        .catch(e => console.error('Error updating video bitrate:', e));
    }
  });
}
