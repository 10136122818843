import React, { Component } from 'react';

class AudioVisualizer extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.audioContext = null;
    this.analyser = null;
    this.dataArray = null;
    this.animationFrame = null;
  }

  componentDidMount() {
    this.initializeVisualizer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stream !== this.props.stream) {
      this.initializeVisualizer();
    }
  }

  componentWillUnmount() {
    // Stop the animation frame
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }

    // Close the audio context to release the audio stream resources
    if (this.audioContext) {
      this.audioContext.close();
    }
  }

  initializeVisualizer = () => {
    // Close any existing audio context to prevent multiple contexts
    if (this.audioContext) {
      this.audioContext.close();
    }

    const { stream } = this.props;

    // Only proceed if a valid MediaStream is provided
    if (!stream || !(stream instanceof MediaStream)) {
      console.warn('No valid MediaStream provided to AudioVisualizer.');
      return;
    }

    try {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = this.audioContext.createMediaStreamSource(stream);

      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 256;
      const bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(bufferLength);

      source.connect(this.analyser);

      this.draw();
    } catch (error) {
      console.error('Error initializing audio visualizer:', error);
    }
  };

  draw = () => {
    if (!this.analyser) return; // Stop drawing if the analyser is not set

    try {
      this.analyser.getByteFrequencyData(this.dataArray);

      const canvas = this.canvasRef.current;
      const canvasCtx = canvas.getContext('2d');
      const width = canvas.width;
      const height = canvas.height;

      canvasCtx.clearRect(0, 0, width, height);

      const barWidth = (width / this.dataArray.length) * 2.5;
      let barHeight;
      let x = 0;

      for (let i = 0; i < this.dataArray.length; i++) {
        barHeight = this.dataArray[i];

        canvasCtx.fillStyle = `rgb(${barHeight + height}, 50, 50)`;
        canvasCtx.fillRect(x, height - barHeight / 2, barWidth, barHeight / 2);

        x += barWidth + 1;
      }

      this.animationFrame = requestAnimationFrame(this.draw);
    } catch (e) {
      console.log('Audio Visualizer Closed');
    }
  };

  render() {
    return (
      <canvas
        ref={this.canvasRef}
        width="250"
        height="45"
        style={{ backgroundColor: '#000', borderRadius: '5px', border: '1px solid #aaa' }}
      />
    );
  }
}

export default AudioVisualizer;
