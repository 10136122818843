import React, { Component } from 'react';

class CameraFeedCollector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localStreams: []
    };
  }

  componentDidMount() {
    this.collectFeeds();
  }

  // Function to collect available video devices one at a time asynchronously
  collectFeeds = async () => {
    // try {
      // console.log('Enumerating devices...');
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputs = devices.filter(device => device.kind === 'videoinput');

      // console.log('Video input devices:', videoInputs);

      const localStreams = [];

      // Step 1: Collect all video input devices with their streams
      for (const device of videoInputs) {
        // try {
          // console.log(`Accessing camera for device ${device.deviceId} (${device.label})...`);
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: device.deviceId },
            audio: false,
          });

          // console.log(`Stream for device ${device.deviceId} received:`, stream);

          // Construct the feed object with initial metadata
          const feedObject = {
            id: this.generateUniqueId(),
            isLocal: true,
            ref: React.createRef(),
            type: 'webcam',
            cameraType: null,
            deviceId: device.deviceId,
            mediaType: 'video',
            videoType: 'webcam',
            audioType: null,
            stream: stream,
            label: device.label || 'Camera',
          };

          localStreams.push(feedObject);
        // } catch (error) {
        //   console.error(`Error accessing camera feed for device ${device.deviceId}:`, error);
        // }
      }

      // Set the local streams in state and pass them to the parent
      this.setState({ localStreams });
      this.props.setLocalStreams(localStreams); // Pass the local streams to the parent component

      // console.log('Local streams:', localStreams);
    // } catch (error) {
    //   console.error('Error collecting camera feeds: ', error);
    // }
  };

  // Helper function to generate unique IDs for streams
  generateUniqueId = () => {
    return `stream_${Math.random().toString(36).substr(2, 9)}`;
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log('Component updated, checking streams for video elements...');
    this.state.localStreams.forEach(feed => {
      if (feed.ref && feed.ref.current) {
        // console.log(`Attaching stream to video element for device ${feed.deviceId}`);
        feed.ref.current.srcObject = feed.stream;
        feed.ref.current.play().catch((err) => {
          console.error(`Error playing video for device ${feed.deviceId}:`, err);
        });
      } else {
        // console.log(`No ref found for feed with device ${feed.deviceId}`);
      }
    });
  }

  render() {
    return null;
  }
}

export default CameraFeedCollector;
